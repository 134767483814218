import axios from 'axios'
import fontawesome from '@fortawesome/fontawesome';
import faCertificate from '@fortawesome/fontawesome-free-solid/faCertificate';
import faSave from '@fortawesome/fontawesome-free-solid/faSave';
import faCheck from '@fortawesome/fontawesome-free-solid/faCheck';
import faExclamationTriangle from '@fortawesome/fontawesome-free-solid/faExclamationTriangle';
import faExclamationCircle from '@fortawesome/fontawesome-free-solid/faExclamationCircle';
import faFilePdf from '@fortawesome/fontawesome-free-solid/faFilePdf';
import faFile from '@fortawesome/fontawesome-free-solid/faFile';
import faPlus from '@fortawesome/fontawesome-free-solid/faPlus';
import faCalendarAlt from '@fortawesome/fontawesome-free-solid/faCalendarAlt';
import faCheckCircle from '@fortawesome/fontawesome-free-solid/faCheckCircle';
import faTimesCircle from '@fortawesome/fontawesome-free-solid/faTimesCircle';

fontawesome.library.add(faCertificate);
fontawesome.library.add(faSave);
fontawesome.library.add(faCheck);
fontawesome.library.add(faExclamationTriangle);
fontawesome.library.add(faExclamationCircle);
fontawesome.library.add(faFilePdf);
fontawesome.library.add(faFile);
fontawesome.library.add(faPlus);
fontawesome.library.add(faCalendarAlt);
fontawesome.library.add(faCheckCircle);
fontawesome.library.add(faTimesCircle);

console.log("begin#end");

document.addEventListener("DOMContentLoaded", cargar_firmador, false);

function cargar_firmador() {
  var btn_firma = document.getElementById('btn-firmar');
  if (btn_firma) {
    btn_firma.addEventListener("click", firmar_pdf, false);
  }
}

function validaciones () {
  var respuesta = false;
  if (document.getElementById('datos-firma').dataset.nrodocumento) {
    respuesta = true;
  } else {
    $.growl.error({ title: "Error", message: 'Debe registrar el número de documento a su usuario.' });
  }
  return respuesta;
}

function firmar_pdf() {
  if (validaciones()) {
    $('#modal-loading-pdf').show()
    var archivos = JSON.parse(document.getElementById('datos-firma').dataset.archivospdf);
    var urlActualizacion = document.getElementById('datos-firma').dataset.urlactualizacion;
    var nroDocumento = document.getElementById('datos-firma').dataset.nrodocumento;
    var datos = {
      "archivo": archivos,
      "format": "pades",
      "ci": nroDocumento,
      "language": "es"
    }
    axios({
      method: 'POST',
      url: 'https://localhost:4637/sign',
      data: datos,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(function(response) {
      if (response.data && response.data.files && response.data.files[0] && response.data.files[0].base64) {
        axios({
          method: 'PUT',
          url: urlActualizacion,
          data: { pago: { pdf_firmado: response.data.files[0].base64 }, tipo: 'pdf' },
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(function(response) {
          $.growl.notice({ title: "Éxito!", message: '(1/2) Se ha firmado correctamente el PDF.' });
          $('#modal-loading-pdf').hide()
          firmar_json()
        })
        .catch(function(error) {
          mostrar_mensaje_error(error);
          $('#modal-loading-pdf').hide()
        });
      } else {
        mostrar_mensaje_error(error);
        $('#modal-loading-pdf').hide()
      }
    })
    .catch(function(error) {
      mostrar_mensaje_error(error);
      $('#modal-loading-pdf').hide()
    });
  }
}

function mostrar_mensaje_error (error) {
  var mensaje = 'Se ha producido un error.';
  if (error.response && error.response.data && error.response.data.message ) {
    mensaje = error.response.data.message;
  }
  $.growl.error({ title: "Error", message: mensaje });
}

function firmar_json() {
  if (validaciones()) {
    $('#modal-loading-json').show()
    var archivos = JSON.parse(document.getElementById('datos-firma').dataset.archivosjson);
    var urlActualizacion = document.getElementById('datos-firma').dataset.urlactualizacion;
    var nroDocumento = document.getElementById('datos-firma').dataset.nrodocumento;
    var datos = {
      "archivo": archivos,
      "format": "jws",
      "ci": nroDocumento,
      "language": "es"
    }
    axios({
      method: 'POST',
      url: 'https://localhost:4637/sign',
      data: datos,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(function(response) {
      if (response.data && response.data.files && response.data.files[0] && response.data.files[0].base64) {
	console.log("begin##################################################");
	console.log(response.data.files[0].base64);
	console.log("end####################################################");
        axios({
          method: 'PUT',
          url: urlActualizacion,
          data: { pago: { json_firmado: response.data.files[0].base64 }, tipo: 'json' },
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(function(response) {
          $.growl.notice({ title: "Éxito!", message: '(2/2) Se ha firmado correctamente el JSON.' });
          $('#modal-loading-json').hide()
          setTimeout(function() {
            location.reload();
          }, 2000);
        })
        .catch(function(error) {
          mostrar_mensaje_error(error);
          $('#modal-loading-json').hide()
        });
      } else {
        mostrar_mensaje_error(error);
        $('#modal-loading-json').hide()
      }
    })
    .catch(function(error) {
      mostrar_mensaje_error(error);
      $('#modal-loading-json').hide()
    });
  }
}
